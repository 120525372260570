<template>
  <v-app id="app">
    <Ayudas></Ayudas>
    <Overlay></Overlay>
    <AppBarras></AppBarras>
    <!-- CONTENIDO -->
    <v-content>
      <v-container pa-0 fluid fill-height class="fondo d-flex align-start">
        <v-container fluid pa-0>
          <router-view></router-view>
        </v-container>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Ayudas from "@/components/Ayudas";
import Overlay from "@/components/Overlay";
import AppBarras from "@/components/AppBarras";
export default {
  name: "App",
  components: {
    Overlay,
    AppBarras,
    Ayudas,
  },
  methods: {
    ...mapMutations("login", ["st_primerIngreso"]),
    ...mapState("login", ["cfgMantenimiento"]),
  },
  created() {
    this.st_primerIngreso();
  },
};
</script>

<style lang="sass">
$barras:#607D8B
$barrasTexto:#EEEEEE
$destacado:rgba(96, 125, 139, 0.34)
$seleccionado:rgba(96, 125, 139, 0.20)

tr.v-data-table__selected
  background-color: $seleccionado !important

.fila_destacada
  background-color: $destacado !important

.fondoBarras
  background-color: $barras

.textoBarras
  color: $barrasTexto

.pInBarra p
  margin: 0px !important

.opaco50
  opacity: 0.5

.moneda::first-letter
  font-size: 60%

.uppercase input
  text-transform: uppercase


/** para que los <pre> se vean bien **/
.note pre
  white-space: pre-wrap
  word-wrap: break-word
  font-family: inherit

.v-data-table-header
  background-color: ($destacado !important )

.v-data-table thead
  border-bottom: none !important
  background-color: (rgba(0, 0, 0, 0.08), !important)

.v-tab--active
  background-color: rgba(255, 255, 255, 0.13)

.font18
  font-size: 18px !important


/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type="number"]
  -moz-appearance: textfield

input[type="number"]
  text-align: right


.v-list-item__subtitle, .v-list-item__title
  white-space: normal !important
  word-wrap: break-word !important
</style>
