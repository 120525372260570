import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: "#2196F3",
        secondary: "#BBDEFB",
        accent: "#1867c0",
        error: "#F44336",
        warning: "#FFC107",
        success: "#4CAF50",
        barras: "#607D8B",// blue-grey,
        barrasLig: "#7997A5", // barras lighten-1
        barrasAyuda: "#FFF9C4", // yellow lighten-4,
        barrasCerrar: "#FFCDD2", //red lighten-4
        barrasMenu: "#BBDEFB", //blue lighten-4
        barrasAceptar: "#C8E6C9", //green lighten-4
        avisoError: "#E57373", //red lighten-2
        avisoOk: "#81C784", // green lighten-2
        btntxtAceptar: "#66BB6A",//green lighten-1
        btntxtCancelar: "#EF5350",//red lighten-1
        btntxtMenu: "#42A5F5", //blue lighten-1
        btntxtAyudas: "#FFEE58", // yellow lighten-1
        barrasTexto: "#EEEEEE",
        barraLateral:"#F5F5F5",
        fondo: "#F5F5F5",// grey lighten-4
        tituloPadrones: "#c9d3d7",
        /* tituloPadrones: "#dcdcdc", */

      },
    },
  },
    lang: {
      locales: { es },
      current: "es",
    },
  icons: {
    iconfont: "md",
  },
});