<template>
  <div>
    <v-overlay :value="stOverCargandoVer" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-overlay :value="stOverAlertaVer" z-index="1000">
      <v-alert class="mt-4 mb-4" prominent :type="stOverAlertaTipo">
        <v-container>
          <v-row align="center">
            <v-col class="grow text-center">
              <span class="note">
                <pre> {{ formatoHtml }}</pre>
              </span>
            </v-col>
            <v-col class="shrink">
              <v-btn @click="stOverAlerta">Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-alert>
    </v-overlay>

    <v-overlay :value="cfgMantenimiento" z-index="100" opacity="1">
      <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
        <v-row align="center">
          <v-col>
            <h1 class="display-2 primary--text">En Mantenimiento</h1>
            <span class="mt-8">
              <pre>{{ cfgMantenimientoMensaje }}</pre>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Overlay",

  computed: {
    ...mapState(["stOverCargandoVer"]),
    ...mapState(["stOverAlertaTipo", "stOverAlertaVer", "stOverAlertaTexto"]),
    ...mapState("login", ["cfgMantenimiento", "cfgMantenimientoMensaje"]),

    formatoHtml() {
      let t = this.stOverAlertaTexto;
      t = t.replace("<br>", "\n", "gi");
      t = t.replace("<br>", "\n", "gi");
      t = t.replace("<br>", "\n", "gi");
      return t;
    },
  },
  methods: {
    ...mapMutations(["stOverAlerta"]),
  },
};
</script>
