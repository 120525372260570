import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/login.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
  },
  {
    path: "/Inicio/:datos?",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
  },
  {
    path: "/turnos",
    name: "Turnos",
    component: () => import("../views/tdsTurnos.vue"),
    meta: { requiresAuth: true },    
  },
  {
    path: "/reclamos",
    name: "Reclamos",
    component: () => import("../views/atrReclamos.vue"),
    meta: { requiresAuth: true },    
  },
  {
    path: "/sueldos",
    name: "Sueldos",
    component: () => import("../views/sldSueldos.vue"),
    meta: { requiresAuth: true },    
  },
  {
    path: "/login/:datos?",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/Padrones",
    name: "Padrones",
    component: () => import("../views/Padrones.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Padron/:IdPadron/:IdTributo?",
    name: "Padron",
    component: () => import("../views/Padron.vue"),
    meta: { requiresAuth: true },
  },
  /*   {
    path: '/Prueba', name: 'Prueba', component: () => import('../views/Prueba.vue'), meta: { requiresAuth: true }
  },   
  {
    path: '/Post', name: 'Post', component: () => import('../views/Post.vue') ,  meta: { requiresAuth: true }
  },  */

  {
    path: "*",
    name: "404",
    component: () => import("../components/NotFoundComponent.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //console.log("logeado " + store.state.stUsuarioLogeado);
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //onsole.log(store.state.stUsuarioLogeado);
    if (!store.state.stUsuarioLogeado) {
      // !auth.loggedIn() si no esta loguead

      next({
        path: "/",
        //query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
