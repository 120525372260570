import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(Vuex, VueAxios, axios);

import login from "./login";

export default new Vuex.Store({
  modules: {
    login: login,
  },
  state: {
    stOverCargandoVer: false, // es el cargando
    stOverAlertaVer: false,
    stOverAlertaTipo: "error",
    stOverAlertaTexto: "",
    stAyudaVer: false,
    pdf: "",
  },
  mutations: {
    stOverCargando(state, { ver = false }) {
      state.stOverCargandoVer = ver;
    },
    stOverAlerta(state, { ver = false, texto = "", tipo = "error" }) {
      state.stOverAlertaTexto = texto;
      state.stOverAlertaTipo = tipo;
      state.stOverAlertaVer = ver;
    },
    stAyuda(state, { ver = false }) {
      state.stAyudaVer = ver;
    },
  },
  actions: {
    /*     alerta(context){
          context.commit('stOverAlerta',{ver:true}) 
          console.log(context)
        } */
  },
  computed: {},
});
