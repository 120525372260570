<template>
  <div v-if="!cfgMantenimiento">
    <!-- BARRA SUPERIOR -->
    <v-app-bar app clipped-left elevate-on-scroll color="barras">
      <v-avatar color="white" size="45">
        <v-img contain height="35" :src="stImagenLogo"></v-img>
      </v-avatar>
      <v-toolbar-title class="ml-3 d-none d-sm-flex">
        <span class="textoBarras">{{ cfgEntidadNombre }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon color="barrasAyuda" v-on="on">
            <v-icon @click="stAyuda({ ver: true })">help_outline</v-icon>
          </v-btn>
        </template>
        <span>Asistencia</span>
      </v-tooltip>
      <!--       <div v-if="stUsuarioLogeado"> -->
      <v-tooltip bottom v-if="stUsuarioTipo > ''">
        <template v-slot:activator="{ on }">
          <v-btn icon color="barrasCerrar" v-on="on">
            <v-icon @click="logout">power_settings_new</v-icon>
          </v-btn>
        </template>
        <span class="text-center">
          Salir del sistema
          <br />
          {{ stUsuarioNombre }}
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="stUsuarioTipo === 'C' || stUsuarioTipo === 'E'">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon color="barrasMenu" @click="drawer = !drawer" v-on="on"></v-app-bar-nav-icon>
        </template>
        <span>Menú</span>
      </v-tooltip>
      <!--       </div> -->
    </v-app-bar>

    <!-- BARRA LATERAL -->
    <v-navigation-drawer v-model="drawer" width="300" app clipped temporary color="barraLateral" right fixed>
      <div class="contenedor">
        <div>
          <v-system-bar window color="barras">
            <span class="textoBarras">Menú</span>
            <v-spacer></v-spacer>
            <v-icon color="barrasCerrar" @click="drawer = !drawer">close</v-icon>
          </v-system-bar>
        </div>

        <div class="pl-3 pr-3">
          <v-list dense class="barraLateral">
            <template>
              <v-list-item to="/login" link>
                <v-list-item-action>
                  <v-icon color="btntxtCancelar">exit_to_app</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Cerrar sesión / Cambiar usuario
                    <br />
                    {{ stUsuarioNombre }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="stUsuarioTipo === 'E'" @click="dlgRecPass = true">
                <v-list-item-action>
                  <v-icon color="btntxtMenu">vpn_key</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">Cambiar contraseña</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider dark class="my-2" />
              <v-list-item to="/Padrones" link>
                <v-list-item-action>
                  <v-icon color="btntxtMenu">list</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">Mis Padrones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider dark class="my-2" />

              <v-row v-if="cfgMensajeIngreso" align="center">
                <v-col cols="12" align="center" class>
                  <v-alert border="top" colored-border color="info" elevation="2">
                    <h4>Informacion Adicional</h4>
                    <v-divider dark class="my-2" />
                    <p class>{{ cfgMensajeDeudaDesde }}</p>
                    <p class="overline">{{ cfgMensajeDeudaAnterior }}</p>
                  </v-alert>
                </v-col>
              </v-row>

              <!--      <v-divider dark class="my-4" />
               <v-list-item to="/Prueba" link>
                <v-list-item-action>
                  <v-icon>archive</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">Prueba</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             <v-list-item to="/Post" link>
                <v-list-item-action>
                  <v-icon>delete</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">Post</v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->
            </template>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- footer -->
    <v-footer app color="barras" class="pt-0 mt-0 pb-0 mb-0" :inset="false">
      <span class="textoBarras px-4 d-none d-sm-flex">{{ cfgEntidadNombre }}</span>
      <v-spacer />
      <span class="textoBarras px-4 d-none d-sm-flex">&copy; {{ new Date().getFullYear() }} Grupo Guadalupe SRL</span>
      <v-btn icon href="https://www.grupoguadalupe.com.ar/" target="new">
        <v-avatar size="25px" item>
          <v-img src="@/assets/ico.png" alt="Grupo Guadalupe"></v-img>
        </v-avatar>
      </v-btn>
    </v-footer>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snaColor" top>
      <span class="note">
        <pre>{{ snaText | saltosDeLineaHTML }}</pre>
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>


    <v-dialog v-model="dlgRecPass" persistent max-width="500" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Cambiar Contraseña</v-card-title>
        <v-card-text>Ingrese dos veces la nueva contraseña</v-card-text>
        <v-card-text>
          <v-form ref="formIngreso" v-model="formPassValid" @submit.prevent>
            <v-text-field autofocus v-model="pass1" label="Nueva Contraseña" :append-icon="verPass ? 'visibility_off' : 'visibility'" prepend-icon="lock" :type="verPass ? 'text' : 'password'" counter @click:append="verPass = !verPass" required :rules="[(v) => !!v || 'campo requerido', (v) => (v && v.length >= 5) || 'Al menos deben ser de 5 caracteres']" />
            <v-text-field v-model="pass2" label="Repetir Nueva Contraseña" :append-icon="verPass ? 'visibility_off' : 'visibility'" prepend-icon="lock" :type="verPass ? 'text' : 'password'" counter @click:append="verPass = !verPass" required :rules="[(v) => !!v || 'campo requerido', (v) => (v && v.length >= 5) || 'Al menos deben ser de 5 caracteres']" />
          </v-form>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn @click="dlgRecPass = false" color="btntxtCancelar" text> <v-icon left>close</v-icon>Cancelar </v-btn>
          <v-spacer />
          <v-btn :disabled="!formPassValid" @click="password()" color="btntxtAceptar" text> <v-icon left>vpn_key</v-icon>Cambiar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "AppBarras",

  computed: {
    ...mapState("login", ["cfgEntidadNombre", "cfgMensajeDeudaDesde", "cfgMensajeDeudaAnterior", "stImagenLogo", "stUsuarioLogeado", "stUsuarioTipo", "cfgMensajeIngreso", "cfgMantenimiento", "stUsuarioNombre", "stUsuarioId"]),
  },
  data: () => ({
    drawer: null, // activa o desactiva la barra
    snackbar: false,
    snaColor: "red",
    snaText: "",
    dlgRecPass: false,
    verPass: false,
    pass1: "",
    pass2: "",
    formPassValid: false,
  }),

  methods: {
    ...mapMutations(["stOverAlerta", "stOverCargando", "stAyuda"]),
    ...mapMutations("login", ["stLogin"]),
    logout() {
      this.stLogin({
        token: "",
        usuarioNombre: "",
        usuarioId: -1,
        usuarioTipo: "",
      });
      this.$router.push("/Inicio");
    },
    async password() {
      if (!(this.pass1 === this.pass2)) {
        //console.log("1");
        this.snaColor = "avisoError";
        this.snaText = "Las contraseñas no coinciden";
        this.snackbar = true;
      } else {
        //console.log("2");
        let bodyFormData = new FormData();
        bodyFormData.set("pass", this.pass1);
        bodyFormData.set("cambiarPass", "S");
        try {
          this.stOverCargando({ ver: true });
          let res = await this.axios.post("recuperarPass", bodyFormData);
          console.log(res);
          if (res.data.resultado) {
            this.snaColor = "avisoOk";
            this.snaText = res.data.mensaje;
            this.snackbar = true;
          } else {
            this.snaColor = "avisoError";
            this.snaText = res.data.mensaje;
            this.snackbar = true;
          }
        } catch (error) {
          //console.log('error')
          this.stOverAlerta({
            ver: true,
            texto: "EL SERVIDOR NO RESPONDE\n" + error,
            tipo: "error",
          });
        } finally {
          this.dlgRecPass = false;
          this.stOverCargando({ ver: false });
        }
      }
    },
  },
};
</script>
