<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="stAyudaVer" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-app-bar app elevate-on-scroll color="barras">
            <v-spacer></v-spacer>
            <v-toolbar-title class="ml-3">
              <span class="title" style="color:#EEEEEE;">{{ ayudaTitulo }}</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn class="mr-3" icon color="barrasCerrar">
              <v-icon @click="stAyuda">close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-container class="mt-10 pt-10">
            <v-row>
              <v-col>
                <div class="text-center" v-if="ver === 'Login'">
                  <v-list-item-title class="title">Datos necesarios para el ingreso.</v-list-item-title>
                  <v-row align="center" justify="center">
                    <v-img max-height="400" contain src="@/assets/loginCodigo.jpeg"></v-img>
                  </v-row>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Los datos necesarios para el ingreso al módulo los encontrara en su boleta.</v-list-item-subtitle>
                      <v-list-item-subtitle>Puede que se le solicite, el tributo (parte superior de la boleta) el número de padrón y la clave, esos datos se encuentran explicados en la imagen.</v-list-item-subtitle>
                      <v-list-item-subtitle>De todas formas, puede comunicarse al municipio para obtener esa información, si no dispone de ninguna boleta.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title">Botón de asistencia</v-list-item-title>
                      <v-list-item-title>
                        <br />
                      </v-list-item-title>
                      <v-row align="center" justify="center">
                        <v-img max-height="400" contain src="@/assets/asistencia.jpeg"></v-img>
                      </v-row>
                      <v-list-item-title>
                        <br />
                      </v-list-item-title>
                      <v-list-item-subtitle>La mayoría de las pantallas cuenta con un botón de asistencia en la barra de la parte superior</v-list-item-subtitle>
                      <v-list-item-subtitle>Dicho botón puede aparecer también cerca de algún campo de carga que requiera alguna explicación</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title">Botoneras inferiores</v-list-item-title>
                      <v-list-item-title>
                        <br />
                      </v-list-item-title>
                      <v-row align="center" justify="center">
                        <v-img max-height="400" contain src="@/assets/botonera.jpeg"></v-img>
                      </v-row>
                      <v-list-item-title>
                        <br />
                      </v-list-item-title>
                      <v-list-item-subtitle>En función de la pantalla donde se encuentre, puede habilitarse una botonera inferior</v-list-item-subtitle>
                      <v-list-item-subtitle>Dicha botonera habilita y deshabilita las diferentes funciones.</v-list-item-subtitle>
                      <v-list-item-subtitle>En los casos de carga de información, también permitirá aceptar o cancelar los cambios.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>

                <div class="text-center" v-if="ver === 'Padrones'">
                  <v-list-item-title class="title">Pantalla de visualización de Padrones.</v-list-item-title>
                  <br />
                  <v-row align="center" justify="center">
                    <v-img max-height="400" contain src="@/assets/padrones.jpeg"></v-img>
                  </v-row>
                  <br />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Esta pantalla permite visualizar él o los padrones asociados a su cuenta.</v-list-item-subtitle>
                      <v-list-item-subtitle>Puede visualizarlos en forma de cuadros o como listado con los botones de "Modo de visualización".</v-list-item-subtitle>
                      <v-list-item-subtitle>El campo de búsqueda le permite encontrar un padrón por cualquiera de sus características (Nro. padrón, domicilio, etc.)</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Para visualizar la información detallada de alguno de los padrones y poder imprimir, liquidar o eventualmente pagar una cuota, solo haga clic sobre él.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                </div>

                <div class="text-center" v-if="ver === 'Padron'">
                  <v-list-item-title class="title">Pantalla de visualización de un Padrón.</v-list-item-title>
                  <br />
                  <v-row align="center" justify="center">
                    <v-img max-height="400" contain src="@/assets/padron.jpeg"></v-img>
                  </v-row>
                  <br />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Esta pantalla permite visualizar las cuotas o períodos de un padrón determinado.</v-list-item-subtitle>
                      <v-list-item-subtitle>En la pestaña "Estado de Períodos" podrá ver las cuotas organizadas según su estado (Adeudadas, pagadas, en proceso de cobro, etc.) como así también las liquidaciones que haya generado.</v-list-item-subtitle>
                      <v-list-item-subtitle>El campo de búsqueda le permite encontrar una cuota por cualquiera de sus características (Numero, período, etc.)</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Parta habilitar la Barra de "Acciones" solo debe ir seleccionando las cuotas, y el sistema ira habilitando las opciones que correspondan.</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Acciones Disponibles:</b>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Podrá reimprimir una cuota y si la misma esta vencida el importe será actualizado.</v-list-item-subtitle>
                      <v-list-item-subtitle>En el caso de seleccionar 2 o mas cuotas podrá generar una liquidación, esto es una única boleta que abarca todas las seleccionadas, para no tener que imprimirlas en forma individual.</v-list-item-subtitle>
                      <v-list-item-subtitle>Si está habilitada la opción (convenios de pagos con tarjetas y bancos), también podrá efectuar el pago de las boletas o liquidación seleccionada.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                </div>

                <div class="text-center" v-if="ver === 'PadronDRI'">
                  <v-list-item-title class="title">Pantalla de visualización Padrón DRI.</v-list-item-title>
                  <br />
                  <v-row align="center" justify="center">
                    <v-img max-height="400" contain src="@/assets/padronDri.jpeg"></v-img>
                  </v-row>
                  <br />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Esta pantalla permite visualizar los períodos de un padrón determinado de DRI.</v-list-item-subtitle>
                      <v-list-item-subtitle>En la pestaña "Estado de Períodos" podrá ver los períodos de diferentes maneras (Últimos 24 períodos, Adeudados, Procesando Pago o Todos).</v-list-item-subtitle>
                      <v-list-item-subtitle>El campo de búsqueda le permite encontrar un periodo por cualquiera de sus características (Numero, período, fecha, etc.)</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Parta habilitar la Barra de "Acciones" solo debe ir seleccionando las cuotas, y el sistema ira habilitando las opciones que correspondan. Tener en cuenta que hay opciones que solo se habilitan con una cuota seleccionada (presentación, rectificación, histórico) y otras permiten selección múltiple</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Acciones Disponibles:</b>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Para un período en particular, podrá presentarlo, rectificarlo, visualizar la última presentación o ver el historial de presentaciones si existe más de una.</v-list-item-subtitle>
                      <v-list-item-subtitle>En el caso de seleccionar múltiples, podrá imprimir una o varias cuotas juntas.</v-list-item-subtitle>
                      <v-list-item-subtitle>Si está habilitada la opción (convenios de pagos con tarjetas y bancos), también podrá efectuar el pago de las boletas seleccionadas.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                  <v-divider></v-divider>
                  <br />
                  <v-list-item-title class="title">Pantalla de Presentación o Rectificación.</v-list-item-title>
                  <br />
                  <v-row align="center" justify="center">
                    <v-img max-height="400" contain src="@/assets/padronDriCarga.jpeg"></v-img>
                  </v-row>
                  <br />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Esta pantalla permite presentar o rectificar un período. Debe tener en cuenta que cada presentación quedará guardada y formará parte del histórico, de ser necesario corregir o subsanar un error, deberá realizar una rectificación nueva.
                        <b>NO SE PERMITE</b> modificación o eliminación de presentaciones.
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Actividades:</b>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>En el caso de la carga de actividades, de tener más de una, la principal aparece destacada en negritas.</v-list-item-subtitle>
                      <v-list-item-subtitle>Según la configuración (no siempre estarán habilitadas todas las opciones), se le permitirá, cargar la base imponible, la alícuota y hasta el importe mínino.</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Adicionales y/o Descuentos:</b>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>En el caso de adicionales y descuentos, solo si es una rectificación le permitirá ingresar el monto ya pagado en el mismo período. Existe un campo específico (si pago de más en un período anterior) para cargar lo pagado en exceso de otros períodos</v-list-item-subtitle>
                      <v-list-item-subtitle>Algunos ítems permiten la carga del Detalle para que quede mejor explicado, tenga a bien completarlo cuando cargue algún importe.</v-list-item-subtitle>
                      <v-list-item-subtitle>Otros ítems tienen el símbolo de asistencia, pase el mouse sobre él y vera una ayuda para la carga de dicho valor.</v-list-item-subtitle>
                      <v-list-item-subtitle>En el caso de combos con opciones, seleccione la que corresponda o ninguna de ser necesario.</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <br />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Acciones Disponibles:</b>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Al finalizar la carga presionando el botón "Presentar" se acepta dicha declaración, y como ya mencionamos, solo podrá modificarse a través de rectificatorias. Por el contrario, si no puede o no desea presentarla, solo presione "Cancelar" y la misma no será presentada.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <br />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Ayudas",
  data() {
    return {
      ayudaTitulo: "",
      ver: "",
    };
  },

  watch: {
    stAyudaVer: function() {
      //console.log("entre");
      if (this.stAyuda) {
        this.ver = this.$router.history.current.name;
        switch (this.ver) {
          case "Login":
            this.ayudaTitulo = "Ingreso al Sistema";
            break;
          case "Padrones":
            this.ayudaTitulo = "Visualización de Padrones";
            break;

          case "Padron":
            if (this.$route.params.IdTributo != 6) this.ayudaTitulo = "Visualización períodos de un Padrón";
            else {
              this.ayudaTitulo = "Visualización períodos padón DRI";
              this.ver = "PadronDRI";
            }
            break;

          default:
            this.ayudaTitulo = "Ayuda no disponible para esta pantalla";
            break;
        }
      }
    },
  },

  created() {
    // console.log(this.$router)
  },

  computed: {
    ...mapState(["stAyudaVer"]),
  },

  methods: {
    ...mapMutations(["stAyuda"]),
  },
};
</script>
