function formatoHtml(texto) {
  if (texto) return texto.replace("\\n", "\n").replace("<br>", "\n");
}
function getFecha(fecha) {
  if (fecha > "") {
    const datos = fecha.split("-");
    return datos[2] + "/" + datos[1] + "/" + datos[0];
  }
}

function getFechaLarga(fecha) {
  if (!fecha) return "";
  var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  var fechaBarra = fecha.replace(/-/g, "\/"); // hay qu ecambiar la fecha del - a la / porque sino la convierte mal
  var fecha1 = new Date(fechaBarra);
  //console.log(fecha, fecha1.toLocaleDateString("es-ES", options) )
  return fecha1.toLocaleDateString("es-ES", options);
}

function completarIZ(valor, caracter, cantidad){
  return valor.toString().padStart(cantidad, caracter)
}

function redirectPost(location, args, target) {
  target || (target = "_blank"); // asigno por defecto si viene nulo;
  var form = document.createElement("form");
  form.action = location;
  form.method = "POST";
  form.target = target;
  form.style.display = "none";
  //console.log(args);
  Object.entries(args).forEach(([key, value]) => {
    var field = document.createElement("input");
    field.type = "hidden";
    field.name = key;
    field.value = value;
    form.append(field);
  });
  //console.log(form);
  document.body.appendChild(form).submit();
  document.body.removeChild(form);
  //form.appendTo('body').submit().remove();
}

function envia() {
  //lo activas con un OnClick
  $.post("http://apirest.gg/sistemas/generarPDF", { reporte: "cuota" }, function(result) {
    WinId = window.open("", "newwin", "width=400,height=500"); //resolucion de la ventana
    WinId.document.open();
    WinId.document.write(result);
    WinId.document.close();
  });
}

export { formatoHtml, getFecha, redirectPost, envia, getFechaLarga, completarIZ };
