import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueCurrencyFilter from "vue-currency-filter";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
});

/* Vue.directive("uppercase", {
  update(el) {
    el.value = el.value.toUpperCase();
  },
});
 */
Vue.filter("completarCeros", function(value) {
  return value.toString().padStart(8, 0);
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("capitalizarPalabras", function( value ) {
  if (!value) return "";
  return value.toLowerCase()
            .replace(/\s+/g,' ')
            .trim()
            .split(' ')
            .map( v => v[0].toUpperCase() + v.substr(1) )
            .join(' ');  
});
Vue.filter("fechaArg", function( value ){
  if (!value) return "";
  const datos = value.split("-");
  return datos[2] + "/" + datos[1] + "/" + datos[0];
});

Vue.filter("FechaLarga", function(fecha) {
  if (!fecha) return "";
  var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  var fechaBarra = fecha.replace(/-/g, "\/"); // hay qu ecambiar la fecha del - a la / porque sino la convierte mal
  var fecha1 = new Date(fechaBarra);
  //console.log(fecha, fecha1.toLocaleDateString("es-ES", options) )
  return fecha1.toLocaleDateString("es-ES", options);
});

Vue.filter("Hora", function(hora) {
  if (!hora) return "";
  const datos = hora.split(":");
  return datos[0] + ":" + datos[1];
});

Vue.filter("saltosDeLineaHTML", function(value) {
  if (!value) return "";
  value = value.replace("<br>", "\n", "gi");
  value = value.replace("<br>", "\n", "gi");
  value = value.replace("<br>", "\n", "gi");
  value = value.replace("<br>", "\n", "gi");
  value = value.replace("<br>", "\n", "gi");    
  return value.replace("<br>", "\n", "gi");
});

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://api.boletaweb.com.ar";
  Vue.config.productionTip = false;
} else {
  //axios.defaults.baseURL = "https://api.boletaweb.com.ar";
  axios.defaults.baseURL = "http://apirest.gg";
  Vue.config.productionTip = true;
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
